function RosterAPI(api_host){
    this.api_host = api_host || location.origin;
}

RosterAPI.prototype = {
    saveRoster: function(itemID, rosterObj, callback, errorCallback) {
        $.ajax({
            url: this.api_host +"/carts/items/"+ itemID + "/update-roster",
            type: "PUT",
            data: {roster: JSON.stringify(rosterObj)},
            success: callback,
            error: errorCallback
        });
    },

    checkRoster: function(itemID, callback, errorCallback) {
        var roster;
        $.ajax({
            url: this.api_host +"/carts/items/"+itemID,
            type: "GET",
            success: callback,
            error: errorCallback
        });
    },

    updateRoster: function(rosterObj, callback, errorCallback) {
        $.ajax({
            url: this.api_host + "/approval/update-roster",
            type: "PUT",
            data: {roster: JSON.stringify(rosterObj)},
            success: callback,
            error: errorCallback
        });
    },
}

RosterAPI.getSizes = function(prolookAPI, material_id, callback, errorCallback) {
    $.ajax({
        url: prolookAPI + "/api/material/" + material_id,
        type: "GET",
        success: callback,
        error: errorCallback
    });
}
